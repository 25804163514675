
.animated-button {
    position: relative;
    cursor: pointer;
}

.animated-button::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
}

.animated-button:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    cursor: pointer;
}
